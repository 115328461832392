<template>
	<div class="flexPage darkPage">
		<div class="flexHead">
			<navbar :params="pageInfo" />
		</div>
		<div>
			<!-- 表单 -->
			<div class="form">
				<div class="cellGroup">
					<div class="tbTitle">{{ $t('form.account.oldpwd') }}</div>
					<van-field type="password" v-model="formValidate.data.oldPassword.value"
						:placeholder="$t('form.account.o1')"></van-field>
				</div>
				<div class="cellGroup">
					<div class="tbTitle">{{ $t('form.signup.emailCode') }}</div>
					<van-field type="password" v-model="formValidate.data.code.value"
						:placeholder="$t('form.assets.p6')"></van-field>
					<button class="btn-code" @click="sendVerificationCode" :disabled="countingDown">{{ buttonText
					}}</button>
				</div>
				<div class="cellGroup">
					<div class="tbTitle">{{ $t('form.account.newpwd') }}</div>
					<van-field type="password" v-model="formValidate.data.newPassword.value"
						:placeholder="$t('form.account.n1')" />
				</div>
				<div class="cellGroup">
					<div class="tbTitle">{{ $t('form.account.repwd') }}</div>
					<van-field type="password" v-model="formValidate.data.rePassword.value"
						:placeholder="$t('form.account.r1')" />
				</div>
			</div>
		</div>

		<van-tabbar>
			<van-tabbar-item>
				<van-button style="width: 327px;" color="#3861FB" :loading="formValidate.submiting" @click="submit()">{{
					$t('button.otc.sure') }}</van-button>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue"
export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$t('form.signup.changePsw'),
				navbarStyle: 'normal'
			},
			formValidate: {
				submiting: false,
				validateAll: false,
				validateTwice: false,
				data: {
					oldPassword: {
						value: "",
						isError: false,
						isDefault: true
					},
					code: {
						value: "",
						isError: false,
						isDefault: true
					},
					newPassword: {
						value: "",
						isError: false,
						isDefault: true
					},
					rePassword: {
						value: "",
						isError: false,
						isDefault: true
					}
				}
			},
			user: {},
			countingDown: false,
			countdownSeconds: 60
		}
	},
	components: { navbar },
	computed: {
		buttonText() {
			return this.countingDown ? `${this.$t('button.assets.reSend')} ${this.countdownSeconds}s` : this.$t('button.assets.sendSms');
		}
	},
	watch: {
		'formValidate.data.oldPassword.value': function () {
			this.$data.formValidate.data.oldPassword.isDefault = false;

			if (this.$vld.checkValue(this.$data.formValidate.data.oldPassword.value, 'password')) {
				this.$data.formValidate.data.oldPassword.isError = false;
			} else {
				this.$data.formValidate.data.oldPassword.isError = true;
			}
		},
		'formValidate.data.newPassword.value': function () {
			this.$data.formValidate.data.newPassword.isDefault = false;

			if (this.$vld.checkValue(this.$data.formValidate.data.newPassword.value, 'password')) {
				this.$data.formValidate.data.newPassword.isError = false;
				//超过6位
				if (this.$data.formValidate.data.newPassword.value == this.$data.formValidate.data.rePassword.value) {
					this.$data.formValidate.validateTwice = true;
				} else {
					//如果不相同
					if (this.$data.formValidate.data.rePassword.isDefault) {
						this.$data.formValidate.validateTwice = true;;
					} else {
						this.$data.formValidate.validateTwice = false;
					}
				}
			} else {
				this.$data.formValidate.data.newPassword.isError = true;
			}
		},
		'formValidate.data.rePassword.value': function () {
			this.$data.formValidate.data.rePassword.isDefault = false;

			if (this.$vld.checkValue(this.$data.formValidate.data.rePassword.value, 'password')) { //1
				this.$data.formValidate.data.rePassword.isError = false;
				if (this.$data.formValidate.data.newPassword.value == this.$data.formValidate.data.rePassword.value) {
					this.$data.formValidate.validateTwice = true;
				} else {
					//如果不相同
					if (this.$data.formValidate.data.newPassword.isDefault) {
						this.$data.formValidate.validateTwice = true;;
					} else {
						this.$data.formValidate.validateTwice = false;
					}
				}
			} else {
				this.$data.formValidate.data.rePassword.isError = true;
			}
		}
	},
	mounted() {
		let that = this;
		that.$mts.checkLogin('needback', (res) => {
			if (res) {
				// 已登录 do thing
			}
		}, 'changePsw');
	},
	created() {
		this.getMember()
	},
	methods: {
		getMember() {
			//获取个人安全信息
			return this.$http.post(this.host + "/uc/approve/security/setting").then(response => {
				var resp = response.body;
				if (resp.code == 0) {
					return new Promise((resolve, reject) => {
						this.user = resp.data;
						resolve(resp.data);
					})
				} else {
					that.$toast(resp.message);
				}
			});
		},
		sendVerificationCode() {
			// 在这里发送获取验证码的请求，这里模拟一个定时器来倒计时
			this.$http.post(this.host + '/uc/email/update/password/code').then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					this.$toast(this.$t('message.emailSuc'));
					this.countingDown = true;
					const timer = setInterval(() => {
						this.countdownSeconds--;
						if (this.countdownSeconds === 0) {
							clearInterval(timer);
							this.countingDown = false;
							this.countdownSeconds = 60;
						}
					}, 1000);
				} else {
					this.countingDown = false;
					this.$toast(resp.message);
				}
			});
		},
		checkAllForm() {
			let that = this;
			if (that.formValidate.data.oldPassword.isDefault
				|| that.formValidate.data.newPassword.isDefault
				|| that.formValidate.data.rePassword.isDefault) {
				return that.$t('validate.public.notNull');
			}

			if (that.formValidate.data.oldPassword.isError) {
				return that.$t('validate.public.oldPswError');
			}
			if (!that.formValidate.data.code.value) {
				return that.$t('form.assets.p6');
			}
			if (that.formValidate.data.newPassword.isError ||
				that.formValidate.data.rePassword.isError) {
				return that.$t('validate.public.newPswError');
			}
			if (!that.formValidate.validateTwice) {
				return that.$t('validate.public.notSame');
			}
			// 验证通过
			return false;
		},
		submit() {
			let that = this;
			if (!that.formValidate.submiting) {
				let message = that.checkAllForm();
				if (!message) { // 验证通过
					that.formValidate.submiting = true;
					let param = {};
					param["oldPassword"] = that.formValidate.data.oldPassword.value;
					param["newPassword"] = that.formValidate.data.newPassword.value;
					param["code"] = that.formValidate.data.code.value;
					that.$http.post(that.host + "/uc/approve/update/password", param).then(response => {
						var resp = response.body;
						that.formValidate.submiting = false;
						if (resp.code == 0) {
							that.$toast(that.$t('validate.public.changePswSuc'));
							that.getMember();
							localStorage.removeItem("user");
							localStorage.removeItem("token");
							that.$store.state.showLogout = true;
							that.$store.state.showLogin = false;
							setTimeout(() => {
								that.$mts.signout();
							}, 2000);
						} else {
							that.$toast(resp.message);
						}
					});
				} else {
					that.$toast(message);
				}
			}
		}
	}
}
</script>

<style scoped>
.cellGroup {
	display: flex;
	align-items: center;
}

.cellGroup .tbTitle {
	text-align: right;
	min-width: 22.9008vw;
}

::v-deep .van-field__control {
	margin-bottom: 0;
}

.van-tabbar {
	height: 20.3562vw;
}

.van-tabbar-item__text {
	width: 90vw;
}

.btn-code {
	margin-right: 4.0712vw;
	box-sizing: border-box;
	border: 1px solid #3861FB;
	color: #3861FB;
	font-size: 3.5623vw;
}
</style>
